<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
          :store-module-name="STORE_MODULE_NAME"
          :refetch-events="refetchEvents"
          :add-new="addNew"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        :store-module-name="STORE_MODULE_NAME"
        :event-data="event"
        :refetch-events="refetchEvents"
        :remove-event-in-calendar="removeEventInCalendar"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
// eslint-disable-next-line import/extensions
import storeModule from '@/store/services/eCommerce/calendarContent'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import thLocale from '@fullcalendar/core/locales/th'
// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
// import useCalendar from './useCalendar'

const STORE_MODULE_NAME = 'calendarContent'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  data() {
    return {
      event: {},
      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        locale: this.$i18n.locale === 'th' ? thLocale : null,
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        events: this.fetchEvents,
        editable: true, // ถ้าจะแก้ไขให้ true
        eventResizableFromStart: false, // ถ้าจะแก้ไขให้ true
        dragScroll: true,
        dayMaxEvents: 2,
        navLinks: true,
        // eslint-disable-next-line no-unused-vars
        // eventClassNames({ event: calendarEvent }) {
        //   // eslint-disable-next-line no-underscore-dangle
        //   const index = store.state[
        //     STORE_MODULE_NAME
        //   ].calendarOptions.findIndex(p => (calendarEvent._def.extendedProps.serviceTypeId !== null
        //     ? p._id.toString()
        //           === calendarEvent._def.extendedProps.serviceTypeId.toString()
        //     : false))
        //   if (index > -1) {
        //     const { colorName } = store.state[
        //       STORE_MODULE_NAME
        //     ].calendarOptions[index]
        //     return [
        //       // Background Color
        //       `bg-light-${colorName}`,
        //     ]
        //   }
        //   // const colorName = 'success'
        //   return [
        //     // Background Color
        //     'bg-grey',
        //   ]
        // },
        eventClick: this.eventClick,
        eventTimeFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          // second: '2-digit',
          hour12: false,
        },
        customButtons: {
          sidebarToggle: {
            // --- This dummy text actual icon rendering is handled using SCSS ----- //
            text: 'sidebar',
            click: this.sidebarToggleClick,
          },
        },
        dateClick: this.dateClick,
        eventDrop: this.eventDrop,
        eventResize: this.eventResize,
        datesSet: this.handleDatesSet, // ใช้ event datesSet
        direction: store.state.appConfig.isRTL ? 'rtl' : 'ltr',
        rerenderDelay: 350,
      },
    }
  },
  computed: {
    calendarApi() {
      return this.$refs.refCalendar.getApi() || null
    },
    selectedCalendars() {
      return store.state[STORE_MODULE_NAME].selectedCalendars
    },
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  methods: {
    handleDatesSet(info) {
      console.log('Current view:', info.view.type)
      console.log('Start date:', info.startStr)
      console.log('End date:', info.endStr)
      this.calendarApi.refetchEvents()
    },
    sidebarToggleClick() {
      console.log('sidebarToggleClick!')

      this.isCalendarOverlaySidebarActive = !this
        .isCalendarOverlaySidebarActive
    },
    eventResize(arg) {
      this.updateEvent(this.grabEventDataFromEventApi(arg.event))
      console.log(`event resize! ${arg}`)
    },
    eventDrop(arg) {
      this.updateEvent(this.grabEventDataFromEventApi(arg.event))
    },
    eventClick(arg) {
      console.log(`event click! ${arg}`)
      console.log(arg)

      arg.jsEvent.preventDefault() // jsEvent
      const existingEvent = this.calendarApi.getEventById(arg.event.id)

      this.event = this.grabEventDataFromEventApi(arg.event) // event
      this.isEventHandlerSidebarActive = true
    },
    dateClick(arg) {
      console.log(`date click! ${arg.dateStr}`)
      console.log(arg)
      const data = {
        _id: null,
        id: null,
        title: '',
        start: arg.date,
        end: arg.date,
        allDay: false,
        url: '',
        extendedProps: {
          description: '',
          color: '#ffffff',
          show: true,
        },
      }
      console.log(data)

      this.event = this.grabEventDataFromEventApi(data)
      this.isEventHandlerSidebarActive = true
    },
    removeEventInCalendar(eventId) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('Removed'),
          icon: 'TrashIcon',
          variant: 'danger',
        },
      })
      this.calendarApi.getEventById(eventId).remove()
    },
    updateEventInCalendar(
      updatedEventData,
      propsToUpdate,
      extendedPropsToUpdate,
    ) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('Updated'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      const existingEvent = this.calendarApi.getEventById(updatedEventData.id)

      // --- Set event properties except date related ----- //
      // ? Docs: https://fullcalendar.io/docs/Event-setProp
      // dateRelatedProps => ['start', 'end', 'allDay']
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < propsToUpdate.length; index++) {
        const propName = propsToUpdate[index]
        existingEvent.setProp(propName, updatedEventData[propName])
      }

      // --- Set date related props ----- //
      // ? Docs: https://fullcalendar.io/docs/Event-setDates
      existingEvent.setDates(updatedEventData.start, updatedEventData.end, {
        allDay: updatedEventData.allDay,
      })

      // --- Set event's extendedProps ----- //
      // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < extendedPropsToUpdate.length; index++) {
        const propName = extendedPropsToUpdate[index]
        existingEvent.setExtendedProp(
          propName,
          updatedEventData.extendedProps[propName],
        )
      }
    },
    updateEvent(eventData) {
      store
        .dispatch(`${STORE_MODULE_NAME}/updateEvent`,  eventData )
        .then(response => {
          const updatedEvent = response.data

          const propsToUpdate = ['id', 'title', 'url']
          const extendedPropsToUpdate = ['code', 'color', 'description', 'show']
          this.updateEventInCalendar(
            updatedEvent,
            propsToUpdate,
            extendedPropsToUpdate,
          )
        })
    },
    refetchEvents() {
      this.calendarApi.refetchEvents()
    },
    fetchEvents(info, successCallback) {
      // If there's no info => Don't make useless API call

      if (!info) return
      // Fetch Events from API endpoint
      // console.log('Current view:', info.view.type)

      store
        .dispatch(`${STORE_MODULE_NAME}/fetchEvents`, {
          startDate: info.startStr,
          endDate: info.endStr,
        })
        .then(response => {
          console.log('fetchEvents ', response.data)
          successCallback(
            response.data.map(p => ({
              _id: p._id,
              id: p.id,
              title: `${p.title}`,
              start: p.start,
              end: p.end,
              url: p.url,
              // eslint-disable-next-line no-nested-ternary
              color: p.extendedProps.color !== null ? p.extendedProps.color !== '#ffffff' ? p.extendedProps.color : null : null,
              textColor: '#ffffff',
              extendedProps: {
                _id: p._id,
                code: p.extendedProps.code,
                color: p.extendedProps.color,
                description: p.extendedProps.description,
                show: p.extendedProps.show,
              },
              allDay: p.allDay,
            })),
          )
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    addNew() {
      this.event = {}
      this.isEventHandlerSidebarActive = true
    },
    grabEventDataFromEventApi(eventApi) {
      const {
        id,
        title,
        start,
        end,
        url,
        // eslint-disable-next-line object-curly-newline
        extendedProps: { _id, code, color, description, show},
        allDay,
      } = eventApi

      return {

        id,
        title: `${title}`,
        start,
        end,
        url,
        extendedProps: {
          _id,
          code,
          color,
          description,
          show,
        },
        allDay,
      }
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/apps/calendar.scss";
  </style>
