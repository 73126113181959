<template>
  <div>
    <b-modal
      :visible="isEventHandlerSidebarActive"
      :title="$t('Calendars')"
      size="lg"
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <b-container fluid>
        <b-form-group
          :label="$t('Title')"
          label-for="event-title"
        >
          <b-form-input
            id="event-title"
            v-model="title"

            autofocus
            trim
            :placeholder="$t('Event Title')"
          />
        </b-form-group>

        <b-form-group
          :label="$t('Start Date')"
          label-for="start-date"
        >
          <flat-pickr
            v-model="start"
            class="form-control"
            :config="configs"
          />
        </b-form-group>

        <b-form-group
          :label="$t('End Date')"
          label-for="end-date"
        >
          <flat-pickr
            v-model="end"
            class="form-control"
            :config="configs"
          />
        </b-form-group>

        <!-- All Day -->
        <b-form-group>
          <b-form-checkbox
            v-model="allDay"
            name="check-button"
            switch
            inline
          >
            {{ $t('All Day') }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          :label="$t('Event URL')"
          label-for="event-url"
        >
          <b-form-input
            id="event-url"
            v-model="url"
            type="url"

            placeholder="htttps://www.google.com"
            trim
          />
        </b-form-group>

        <!-- Textarea -->
        <b-form-group
          :label="$t('Description')"
          label-for="event-description"
        >
          <b-form-textarea
            id="event-description"
            v-model="extendedProps.description"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Color')"
          label-for="event-color"
        >
          <b-row>
            <b-col cols="6">
              <b-form-input
                id="event-color"
                v-model="extendedProps.color"
                placeholder="Color"
                type="color"
              />
            </b-col>
            <b-col cols="6">
              <b-form-input
                id="event-color"
                v-model="extendedProps.color"
                placeholder="Color"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Show -->
        <b-form-group>
          <b-form-checkbox
            v-model="extendedProps.show"
            name="check-button"
            switch
            inline
          >
            {{ $t('Show') }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="id !== null"
          :label="$t('List')"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            size="sm"
            @click="addList({})"
          >{{ $t('Add list') }}
          </b-button>
          <b-table
            ref="refUserListTable"
            striped
            responsive
            class="position-relative"
            :items="list"
            :fields="tableColumns"
            primary-key="_id"
            show-empty
            :empty-text="$t('No matching records found')"
            style="height: 60vh;"
          >

            <template #cell(title)="data">
              <p
                class="align-middle ml-50"
              >{{ (data.item.title) }}</p>
            </template>
            <!-- Column: Action -->
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="addList(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteDataList( data.item._id )">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-form-group>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="id !== null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            class="float-left mb-0"
            @click="removeEvent"
          >{{ $t('Delete') }}</b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="$emit('update:is-event-handler-sidebar-active', false)"
          >{{ $t('Cancel') }}</b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="validationForm"
          >{{ id !== null ? $t('Update') : $t('Add') }}</b-button>
        </div>
      </template>
    </b-modal>
    <calendar-list
      :is-calendar-list-handler-sidebar-active.sync="isCalendarListHandlerSidebarActive"
      :store-module-name="storeModuleName"
      :event-data="event"
    />
  </div>
</template>

<script>
import {
  BTable,
  BModal,
  VBModal,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/extensions
import { Thai } from 'flatpickr/dist/l10n/th.js'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref, toRefs } from '@vue/composition-api'
import CalendarList from '../calendar-list/CalendarList.vue'

export default {
  components: {
    CalendarList,
    BTable,
    BButton,
    BModal,
    BContainer,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    eventData: {
      type: Object,
      required: true,
    },
    removeEventInCalendar: {
      type: Function,
      required: true,
    },
    refetchEvents: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isCalendarListHandlerSidebarActive: false,
      event: {},
      configs: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        locale: Thai,
      },
      id: null,
      title: '',
      start: '',
      end: '',
      allDay: false,
      url: '',
      extendedProps: {
        description: '',
        color: '#ffffff',
        show: true,
      },
      // list: [],
      required,
    }
  },
  computed: {
    tableColumns() {
      return [

        { key: 'title', label: this.$t('Title'), sortable: false },

        // { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    list() {
      return store.state[this.storeModuleName].list != null ? store.state[this.storeModuleName].list : []
    },
  },
  watch: {
    isEventHandlerSidebarActive(val) {
      if (val) {
        if (Object.entries(this.eventData).length === 0) {
          this.initValues()
        } else {
          const {
            _id,
            id,
            title,
            start,
            end,
            allDay,
            url,
            extendedProps,
          } = this.eventData

          this.dataId = extendedProps._id
          this.id = id
          this.title = title
          this.start = start
          this.end = end
          this.allDay = allDay
          this.url = url
          this.extendedProps = extendedProps
          this.fetchList()
        }
      }
    },
  },
  methods: {
    deleteDataList(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$emit('update:show', true)

            const obj = { id: this.dataId, _id: id }
            store
              .dispatch(`${this.storeModuleName}/removeList`, obj)
              .then(result => {
                this.$emit('update:show', false)
              }).catch(error => {
                this.$emit('update:show', false)

                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
        })
    },
    fetchList() {
      store
        .dispatch(`${this.storeModuleName}/fetchList`, {
          id: this.dataId,
        })
        .then(response => {
          console.log(response.data)
          // this.list = response.data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    addList(item) {
      if (Object.keys(item).length === 0) {
        this.event = {
          id: null,
          title: '',
          start: '',
          end: '',
          allDay: false,
          url: '',
          extendedProps: {
            calendarContentCategoryId: this.dataId,
            code: '',
            color: '#ffffff',
            description: '',
            show: true,
            html: [
              {
                lang: 'th',
                value: '',
              },
              {
                lang: 'en',
                value: '',
              },
            ], // ตัวเรนเดอร์จาก html
            htmlCode: [
              {
                lang: 'th',
                value: '',
              },
              {
                lang: 'en',
                value: '',
              },
            ], // ตัวเรนเดอร์จาก html
            htmlCSS: [
              {
                lang: 'th',
                value: '',
              },
              {
                lang: 'en',
                value: '',
              },
            ], // ตัวเรนเดอร์จาก html
          },
        }
      } else {
        this.event = this.grabCalendarListFromEventApi(item)
      }
      this.isCalendarListHandlerSidebarActive = true
    },
    grabCalendarListFromEventApi(eventApi) {
      const {
        _id,
        id,
        title,
        start,
        end,
        url,
        // eslint-disable-next-line object-curly-newline
        extendedProps: { code, color, description, show, html, htmlCode, htmlCSS },
        allDay,
      } = eventApi

      return {
        _id,
        id,
        title: `${title}`,
        start,
        end,
        url,
        extendedProps: {
          calendarContentCategoryId: this.dataId,
          code,
          color,
          description,
          show,
          html,
          htmlCode,
          htmlCSS,
        },
        allDay,
      }
    },
    removeEvent() {
      const eventId = this.id
      if (eventId !== null) {
        this.$bvModal
          .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'danger',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          })
          .then(value => {
            if (value) {
              store
                .dispatch(`${this.storeModuleName}/removeEvent`, {
                  id: eventId,
                  _id: this.dataId,
                })
                .then(() => {
                  this.$emit('update:is-event-handler-sidebar-active', false)
                  this.removeEventInCalendar(eventId)
                })
            }
          })
      }
    },
    initValues() {
      this._id = null
      this.id = null
      this.title = ''
      this.start = ''
      this.end = ''
      this.allDay = false
      this.url = ''
      this.extendedProps = {
        description: '',
        color: '#ffffff',
        show: true,
      }
    },

    validationForm() {
      const obj = {
        _id: this._id,
        id: this.id,
        title: this.title,
        start: this.start,
        end: this.end,
        allDay: this.allDay,
        url: this.url,
        extendedProps: this.extendedProps,
      }
      this.$emit('update:show', true)
      if (this.id !== null) {
        store
          .dispatch(`${this.storeModuleName}/updateEvent`, obj)
          .then(result => {
            this.$emit('update:is-event-handler-sidebar-active', false)
            this.$emit('update:show', false)
            console.log('fetch Success : ', result)

            this.refetchEvents()

            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.$emit('update:show', false)
            // this.showToast('danger', 'bottom-right')
          })
      } else {
        delete obj.id
        store
          .dispatch(`${this.storeModuleName}/addEvent`, obj)
          .then(result => {
            this.$emit('update:is-event-handler-sidebar-active', false)
            this.$emit('update:show', false)
            console.log('fetch Success : ', result)

            this.refetchEvents()

            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.$emit('update:show', false)
            // this.showToast('danger', 'bottom-right')
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
