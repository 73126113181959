import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respDataPartner: null,
    profilePartnerId: '',
    calendarOptions: [
      // {
      //   color: 'danger',
      //   label: 'Personal',
      // },
      // {
      //   color: 'primary',
      //   label: 'Business',
      // },
      // {
      //   color: 'warning',
      //   label: 'Family',
      // },
      // {
      //   color: 'success',
      //   label: 'Holiday',
      // },
      // {
      //   color: 'info',
      //   label: 'ETC',
      // },
    ],
    selectedCalendars: [],
    list: [],
  },
  getters: {},
  mutations: {
    GET_PARTNER(state, payload) {
      state.respDataPartner = payload
    },
    GET_LIST(state, payload) {
      state.list = payload
    },
    ADD_LIST(state, payload) {
      state.list.unshift(payload)
    },
    UPDATE_LIST(state, payload) {
      const productIndex = state.list.findIndex(p => p._id === payload._id)
      Object.assign(state.list[productIndex], payload)
    },
    REMOVE_LIST(state, id) {
      if (state.list != null) {
        const ItemIndex = state.list.findIndex(p => p._id === id)
        state.list.splice(ItemIndex, 1)
      }
    },
    SET_SELECT_PROFILEPARTNERID(state, payload) {
      state.profilePartnerId = payload
    },
    SET_CALENDAR_TYPE(state, payload) {
      state.calendarOptions = payload
      state.selectedCalendars = payload.map(p => p._id)
    },
    SET_SELECT_CALENDAR(state, payload) {
      state.selectedCalendars = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchEvents(ctx, { startDate, endDate }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/web/application/eCommerce/calendarContent/get', {
            params: {
              startDate,
              endDate,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/web/application/eCommerce/calendarContent/add', { event })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/calendarContent/update', { event })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id, _id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/web/application/eCommerce/calendarContent/delete?id=${id}&_id=${_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchList({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/web/application/eCommerce/calendarContent/getList', {
            params: {
              id,
            },
          })
          .then(response => {
            commit('GET_LIST', response.data.data)
            resolve(response.data)})
          .catch(error => reject(error))
      })
    },
    addList({ commit }, event) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/web/application/eCommerce/calendarContent/addList', { event })
          .then(response => {
            commit('ADD_LIST', response.data.data)
            resolve(response.data)})
          .catch(error => reject(error))
      })
    },
    updateList({ commit }, event) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/calendarContent/updateList', { event })
          .then(response => {
            commit('UPDATE_LIST', response.data.data)
            resolve(response.data)})
          .catch(error => reject(error))
      })
    },
    removeList({ commit }, { id, _id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/web/application/eCommerce/calendarContent/deleteList?id=${id}&_id=${_id}`)
          .then(response => {
            commit('REMOVE_LIST', _id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
