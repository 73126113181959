<template>
  <div>
    <b-modal
      :visible="isCalendarListHandlerSidebarActive"
      :title="$t('Calendars')"
      size="lg"
      @change="(val) => $emit('update:is-calendar-list-handler-sidebar-active', val)"
    >
      <b-container fluid>
        <b-form-group
          :label="$t('Title')"
          label-for="event-title"
        >
          <b-form-input
            id="event-title"
            v-model="title"

            autofocus
            trim
            :placeholder="$t('Event Title')"
          />
        </b-form-group>

        <b-form-group
          :label="$t('Start Date')"
          label-for="start-date"
        >
          <flat-pickr
            v-model="start"
            class="form-control"
            :config="configs"
          />
        </b-form-group>

        <b-form-group
          :label="$t('End Date')"
          label-for="end-date"
        >
          <flat-pickr
            v-model="end"
            class="form-control"
            :config="configs"
          />
        </b-form-group>

        <!-- All Day -->
        <b-form-group>
          <b-form-checkbox
            v-model="allDay"
            name="check-button"
            switch
            inline
          >
            {{ $t('All Day') }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          :label="$t('Event URL')"
          label-for="event-url"
        >
          <b-form-input
            id="event-url"
            v-model="url"
            type="url"

            placeholder="htttps://www.google.com"
            trim
          />
        </b-form-group>

        <!-- Textarea -->
        <b-form-group
          :label="$t('Description')"
          label-for="event-description"
        >
          <b-form-textarea
            id="event-description"
            v-model="extendedProps.description"
          />
        </b-form-group>

        <!-- Show -->
        <b-form-group>
          <b-form-checkbox
            v-model="extendedProps.show"
            name="check-button"
            switch
            inline
          >
            {{ $t('Show') }}
          </b-form-checkbox>
        </b-form-group>

        <b-row>
          <b-col cols="12">
            <b-tabs v-model="tabIndex">
              <b-tab
                v-for="(itemLang, indexLang) in language"
                :key="indexLang"
                :title="$t(itemLang.name)"
              >
                <b-form class="mt-2">
                  <b-row>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >

                      <div class="code">
                        <div class="html-code">
                          <b-tabs>
                            <b-tab :title="'Editor'">
                              <h1>Editor</h1>

                              <vue-editor
                                v-if="extendedProps.html[indexLang]"
                                :id="`editor${itemLang.lang}`"
                                :ref="`editor_${itemLang.lang}`"
                                :value="extendedProps.html[indexLang]['value']"
                                :editor-toolbar="customToolbar"
                                @input="
                                  inputLanguage(
                                    $event,
                                    itemLang.lang,
                                    indexLang,
                                    'htmlCode',
                                  )
                                "
                              />
                            </b-tab>
                            <b-tab :title="'HTML'">
                              <h1>HTML</h1>
                              <b-form-textarea
                                v-if="extendedProps.htmlCode[indexLang]"

                                :value="extendedProps.htmlCode[indexLang]['value']"

                                @input="
                                  inputLanguage(
                                    $event,
                                    itemLang.lang,
                                    indexLang,
                                    'htmlCode',
                                  )
                                "
                              />
                            </b-tab>
                          </b-tabs>

                        </div>
                        <div class="css-code">
                          <h1>CSS</h1>
                          <b-form-textarea
                            v-if="extendedProps.htmlCSS[indexLang]"
                            :value="extendedProps.htmlCSS[indexLang]['value']"

                            @input="
                              inputLanguage(
                                $event,
                                itemLang.lang,
                                indexLang,
                                'htmlCSS',
                              )
                            "
                          />
                        </div>
                      </div>

                    </b-col>
                    <b-col
                      cols="12"
                      class="mb-2"
                    >
                      <h1>{{ $t('Example') }}</h1>
                      <iframe
                        :id="`htmlRender_${itemLang.lang}`"
                        style="width: 100%;
                        height: 100%;
                        border: none;
                        border-radius:4px;
                        border:solid 0.5px #999;
                        "
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="id !== null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            class="float-left mb-0"
            @click="removeEvent"
          >{{ $t('Delete') }}</b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="$emit('update:is-calendar-list-handler-sidebar-active', false)"
          >{{ $t('Cancel') }}</b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="validationForm"
          >{{ id !== null ? $t('Update') : $t('Add') }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BModal,
  VBModal,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/extensions
import { Thai } from 'flatpickr/dist/l10n/th.js'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref, toRefs } from '@vue/composition-api'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
    BTabs,
    BTab,
    BButton,
    BModal,
    BContainer,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isCalendarListHandlerSidebarActive',
    event: 'update:is-calendar-list-handler-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isCalendarListHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    eventData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tabIndex: 0,
      configs: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        locale: Thai,
      },
      // eslint-disable-next-line vue/no-reserved-keys
      _id: null,
      id: null,
      title: '',
      start: '',
      end: '',
      allDay: false,
      url: '',
      extendedProps: {
        calendarContentCategoryId: null,
        code: '',
        description: '',
        show: true,
        html: [
          {
            lang: 'th',
            value: '',
          },
          {
            lang: 'en',
            value: '',
          },
        ], // ตัวเรนเดอร์จาก html
        htmlCode: [
          {
            lang: 'th',
            value: '',
          },
          {
            lang: 'en',
            value: '',
          },
        ], // ตัวเรนเดอร์จาก html
        htmlCSS: [
          {
            lang: 'th',
            value: '',
          },
          {
            lang: 'en',
            value: '',
          },
        ], // ตัวเรนเดอร์จาก html
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // [
        //   { align: '' },
        //   { align: 'center' },
        //   { align: 'right' },
        //   { align: 'justify' },
        // ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link', 'image', 'video'],
        ['clean'], // remove formatting button
      ],
      required,
    }
  },
  computed: {
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  watch: {
    isCalendarListHandlerSidebarActive(val) {
      if (val) {
        if (Object.entries(this.eventData).length === 0) {
          this.initValues()
        } else {
          const {
            _id,
            id,
            title,
            start,
            end,
            allDay,
            url,
            extendedProps,
          } = this.eventData

          this.dataId = extendedProps._id
          this._id = _id
          this.id = id
          this.title = title
          this.start = start
          this.end = end
          this.allDay = allDay
          this.url = url
          this.extendedProps = extendedProps
        }
      }
    },
    tabIndex(val) {
      if (this.extendedProps.htmlCode.length > 0 && this.extendedProps.htmlCSS.length > 0) {
        this.renderCode(val)
      }
    },
  },
  methods: {
    renderCode(indexLang) {
      const locale = this.language[indexLang].lang
      const result = document.querySelector(`#htmlRender_${locale}`)

      const htmlCode = this.extendedProps.htmlCode[indexLang].value
      const htmlCSS = this.extendedProps.htmlCSS[indexLang].value

      result.contentDocument.body.innerHTML = `<style>${htmlCSS}</style>${htmlCode}`
    },
    removeEvent() {
      const eventId = this.id
      if (eventId !== null) {
        this.$bvModal
          .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'danger',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          })
          .then(value => {
            if (value) {
              store
                .dispatch(`${this.storeModuleName}/removeList`, {
                  id: eventId,
                  _id: this._id,
                })
                .then(() => {
                  this.$emit('update:is-calendar-list-handler-sidebar-active', false)
                })
            }
          })
      }
    },
    inputLanguage(input, lang, index, type) {
      console.log(input, lang, index, type)
      if (type === 'htmlCode') {
        if (this.extendedProps.htmlCode[index].lang === lang) {
          this.extendedProps.htmlCode[index].value = input
          this.renderCode(index)
        }
      } else if (type === 'htmlCSS') {
        if (this.extendedProps.htmlCSS[index].lang === lang) {
          this.extendedProps.htmlCSS[index].value = input
          this.renderCode(index)
        }
      }
      console.log(this.name)
    },
    initValues() {
      this._id = null
      this.id = null
      this.title = ''
      this.start = ''
      this.end = ''
      this.allDay = false
      this.url = ''
      this.extendedProps = {
        calendarContentCategoryId: null,
        code: '',
        description: '',
        show: true,
        html: [
          {
            lang: 'th',
            value: '',
          },
          {
            lang: 'en',
            value: '',
          },
        ], // ตัวเรนเดอร์จาก html
        htmlCode: [
          {
            lang: 'th',
            value: '',
          },
          {
            lang: 'en',
            value: '',
          },
        ], // ตัวเรนเดอร์จาก html
        htmlCSS: [
          {
            lang: 'th',
            value: '',
          },
          {
            lang: 'en',
            value: '',
          },
        ], // ตัวเรนเดอร์จาก html
      }
    },

    validationForm() {
      const obj = {
        _id: this._id,
        id: this.id,
        title: this.title,
        start: this.start,
        end: this.end,
        allDay: this.allDay,
        url: this.url,
        extendedProps: {
          ...this.extendedProps,

          html: this.extendedProps.html.map((e, index) => ({
            lang: e.lang,
            value: `<style>${this.extendedProps.htmlCSS[index].value}</style>${this.extendedProps.htmlCode[index].value}`,
          })),
        },
      }
      console.log(obj)
      this.$emit('update:show', true)
      if (this.id !== null) {
        store
          .dispatch(`${this.storeModuleName}/updateList`, obj)
          .then(result => {
            this.$emit('update:is-calendar-list-handler-sidebar-active', false)
            this.$emit('update:show', false)
            console.log('fetch Success : ', result)

            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.$emit('update:show', false)
            // this.showToast('danger', 'bottom-right')
          })
      } else {
        delete obj.id
        store
          .dispatch(`${this.storeModuleName}/addList`, obj)
          .then(result => {
            this.$emit('update:is-calendar-list-handler-sidebar-active', false)
            this.$emit('update:show', false)
            console.log('fetch Success : ', result)

            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.$emit('update:show', false)
            // this.showToast('danger', 'bottom-right')
          })
      }
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
